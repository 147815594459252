<template>
  <el-card class="box-card" :class="activeName === 'first' ? 'leftBorder' : '' ">
  <div class="main-unit">
    <!--左侧树形-->
    <div v-if="activeName === 'first'" class="left-tree">
      <el-tree :data="treedata"
               accordion
               v-loading="loading"
               default-expand-all
               element-loading-text="拼命加载中"
               empty-text="暂无数据"
               :props="defaultProps"
               @node-click="handleNodeClick"></el-tree>
    </div>
    <!--右侧表格-->
    <div class="right-info" :class="activeName === 'first' ? 'ml270px' : '' ">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <!--分开写/考虑扩展代码不冗杂-->
        <el-tab-pane :label="`审核通过(${totalCount.auditPass ? totalCount.auditPass : 0})`" name="first">
          <!--审核通过-->
          <unitApproved :currentTreeId="currentTreeId" v-if="activeName === 'first'"/>
        </el-tab-pane>
        <el-tab-pane :label="`待审核(${totalCount.auditAwait?totalCount.auditAwait:0})`" name="second">
          <unitWaitApproved  @refreshCount="refreshCount" v-if="activeName === 'second'"/>
        </el-tab-pane>
        <el-tab-pane :label="`审核不通过(${totalCount.auditNoPass?totalCount.auditNoPass:0})`" name="third">
          <unitFailedApproved  v-if="activeName === 'third'"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  </el-card>
</template>

<script>
  import unitApproved from "./components/unitApproved";
  import unitWaitApproved from "./components/unitWaitApproved";
  import unitFailedApproved from "./components/unitFailedApproved";
  import { getAuditSubordinateGovernmentList,getStatisticsUnit } from "@/api/unitManage"
  export default {
    name: "unitManagement",
    components: {
      unitApproved, //审核通过
      unitWaitApproved, //等待审核
      unitFailedApproved //审核不通过
    },
    data() {
      return {
        loading: false,
        activeName: 'first',
        treedata: [],
        currentTreeId: '', //点击当前数节点ID
        totalCount: {}, //统计数量
        defaultProps: {
          id: 'id',
          label: 'name',
          children: 'superiorGovernmentDtoList'
        }
      }
    },
    mounted() {
      /*解决页面刷新停留在当前tabs页下*/
      this.activeName = localStorage.getItem('unitTabs') ? localStorage.getItem('unitTabs') : 'first'
      this.getAuditSubordinateGovernmentListData()
      this.getStatisticsUnitData()
    },
    destroyed() { //页面销毁 清除tabs
      localStorage.removeItem('unitTabs')
    },
    methods: {
      /*树选择*/
      handleNodeClick(data) {
        this.currentTreeId = data.id
      },
      /*tabs选择*/
      handleClick(data) {
        this.currentTreeId = ''
        this.getStatisticsUnitData()
        localStorage.setItem('unitTabs', data.name)
      },
      //刷新
      refreshCount() {
        this.getStatisticsUnitData()
      },
      /*左侧树选择*/
      async getAuditSubordinateGovernmentListData() {
        this.loading = true
        const res = await getAuditSubordinateGovernmentList()
        if(res.data instanceof Array) {
          this.treedata = res.data
        }
        this.loading = false
      },
    //  统计数量
      async getStatisticsUnitData() {
        const res = await getStatisticsUnit()
        if(res.state === 0) {
          this.totalCount = res.data
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .leftBorder {
    position: relative;
  }
  .box-card {
    min-height: calc(100% - 10px);

  }
  .main-unit {
    background-color: #FFFFFF;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .left-tree {
      float: left;
      padding: 26px 0 0 26px;
      width: 269px;
      box-sizing: border-box;
      display: inline-block;
    }
    .right-info {
      box-sizing: border-box;
      /*margin-left: 270px;*/
      /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
        /*border-left: none !important;*/
        /*border-top-left-radius: 0 !important;*/
      }

    }

  }
  .leftBorder:before {
    content: '';
    height: 100%;
    border-right: 1px solid #EEEEEE;
    position: absolute;
    top: 60px;
    left: 290px;
  }
  .ml270px {
    margin-left: 270px;
  }
</style>
